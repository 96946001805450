@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

$font-1: "Roboto", sans-serif;

$color-1: #f7f7f7;
$color-2: #20c997;
$color-3: #111418;
$color-4: #e7e7e7;
$color-5: #09a3cd;
$color-6: #fff;
$color-7: #aaeafc;
$color-8: #c4f2ff;

$margin-container: 300px;
$margin-menu: 60px;
$padding-small: 15px; 

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0 0 auto 20px;
  width: 95%;
  font-family: $font-1;
  color: $color-3;
  font-size: 1.3rem;
  background: linear-gradient(-45deg,$color-6,$color-1,$color-4, grey);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
}
a:visited {
  color: $color-3;
}

h1 {
  color: $color-2;
  font-weight: bold;
  font-size: 4rem;
}

b {
  color: $color-5;
}

.contactLink {  
  display: block;
  border : 2px solid skyblue;
  background-color: skyblue;
  font-weight: bold;
  width: 300px;
  height: 65px;
  padding: 3px;
  border-radius: 15px;
  margin: 20px auto auto auto;
}

.contactLink:hover {
  background-color: #20c997;
}
