.agapes-container {
    margin-top: 20px;
    text-align: center;
    form {
        width: 50%;
        margin: auto;
        fieldset {
            padding: 20px;
        }
        label {
            display: inline-block;
            font-weight: bold;
            width: 100px
        }
        legend {
            margin: auto;
            font-weight: bold;
        }
        select {
            cursor: pointer;
            width: 100%;
            padding: 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 6px;
            margin-bottom: 16px;
            resize: vertical;
        }
        input[type=submit] {
            background-color: #311c56;
            color: white;
            padding: 12px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            width: 100%;
            margin-top: 15px;
        }
        option {
            text-transform: capitalize;
            cursor: pointer;
        }
        input[type=checkbox] {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            width: 50px;
            height: 50px;
            border-radius: 2px;
            border : 1px solid black;
            cursor: pointer;
            position: relative;
        }
        input[type=checkbox]:checked {
            background: green;
        }
    }
    }

    
    @media (max-width: 900px) {
        select, option {
            height: 80px;
            font-size: 16px;
        }
        h1 {
            font-size: smaller;
        }
     legend {
        display: none;
     }
    }
